import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import FilterForm from '../../components/FilterForm/FilterForm';
import qs from 'qs';

import { v4 as uuidv4 } from 'uuid';
import { isCompositeComponent } from 'react-dom/test-utils';

const CityMaster = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  // Edit Data for editing existing record
  const [editData, setEditData] = useState(null);

  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();
  const [Data, setData] = useState([]);
  const [dataform] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [stateListForFilter, setStateListForFilter] = useState([]);

  const [selectedCountryValue, setSelectedCountryValue] = useState(null);
  const [selectedCountryValueForFilter, setSelectedCountryValueForFilter] =
    useState(null);
  const [selectedStateValue, setSelectedStateValue] = useState(null);

  let data1 = [];

  const fetchData = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/cities?pagination[page]=${current}&pagination[pageSize]=${pageSize}&populate[0]=state&populate[state][populate][0]=country&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const responseData = await response.json();
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        // return;
        data1 = responseData.data.map((entry) => {
          console.log(responseData);

          let stateObj = {};
          if (entry.attributes.state.data) {
            stateObj = {
              stateRefId: entry.attributes.state.data.id,
              stateName: entry.attributes.state.data.attributes.stateName,
              countryObj: entry.attributes.state.data.attributes.country.data,
            };
          }

          console.log(stateObj.countryObj);

          let countryObj = {};
          if (stateObj.countryObj) {
            countryObj = {
              countryRefId: stateObj.countryObj.id,
              countryName: stateObj.countryObj.attributes.countryName,
            };
          }

          return {
            id: entry.id,
            cityName: entry.attributes.cityName,
            stateDetails: stateObj,
            countryDetails: countryObj,
          };
        });

        console.log('original Data:', responseData);
        console.log('current Data after modification:', data1);

        setData(data1);

        setTotal(responseData.meta.pagination.total);
        setPageSize(responseData.meta.pagination.pageSize);
        setPageCount(responseData.meta.pagination.pageCount);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  // selecting the dropdowns data
  const fetchCountries = async (token) => {
    let countriesData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/countries?`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const responseData = await response.json();
      console.log(responseData);
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        countriesData2 = responseData.data.map((entry) => {
          return {
            id: entry.id,
            countryName: entry.attributes.countryName,

            // launch_masters: entry.attributes.launch_masters,
          };
        });

        setCountryList(countriesData2);
        console.log('original countries Data:', responseData);
        console.log('modified countries Data:', countriesData2);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  }; // end function fetchSeason

  const fetchState = async (token) => {
    let stateData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/states?`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const responseData = await response.json();
      console.log(responseData);
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        stateData2 = responseData.data.map((entry) => {
          return {
            id: entry.id,
            stateName: entry.attributes.stateName,
          };
        });

        setStateList(stateData2);
        setStateListForFilter(stateData2);
        console.log('original state Data:', responseData);
        console.log('modified state Data:', stateData2);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  }; // end function fetchSeason

  const handleCountryChageForFilter = (value) => {
    console.log('countryChage method called for filter', value);
    // setSelectedCountryValueForFilter(value);
  };

  const handleCountryChage = (value) => {
    console.log('countryChage method called', value);
    setSelectedCountryValue(value);
  };

  useEffect(() => {
    const fetchSelectedState = async (token) => {
      let stateData2 = [];
      setIsLoading(true);
      try {
        const response = await fetch(
          `${API}/states?populate[0]=country&filters[country][id][$eq]=${selectedCountryValue}`,
          {
            headers: { Authorization: `${BEARER} ${token}` },
          }
        );
        const responseData = await response.json();
        console.log('dependent select', responseData);
        if (responseData.error) {
          message.error('Something went wrong, please try again');
        } else {
          stateData2 = responseData.data.map((entry) => {
            return {
              id: entry.id,
              stateName: entry.attributes.stateName,
            };
          });

          setStateList(stateData2);
          console.log('original state Data:', responseData);
          console.log('modified state Data:', stateData2);
        }
      } catch (error) {
        console.error(error);
        message.error('Error while fetching Data!');
      } finally {
        setIsLoading(false);
      }
    }; // end function fetchSelectedState

    fetchSelectedState(getToken());
  }, [selectedCountryValue]);

  useEffect(() => {
    if (authToken) {
      fetchData(authToken);
      fetchCountries(authToken);
      fetchState(authToken);
    }
  }, []);

  // for opening add or edit form with data or change in the reference of data form when form is closed or page is reloaded
  useEffect(() => {
    //dataform data
    console.log(editData);
    dataform.setFieldsValue(editData);
  }, [editData, dataform]);

  if (isLoading) {
    return <Spin size="large" />;
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  // hide and reset add/edit form inside modal
  const handleOk = () => {
    dataform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  // hide and reset add/edit form inside modal
  const handleCancel = () => {
    dataform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  const convertToQueryString = (values) => {
    // filterAttribute: valuesArray,
    // filterAttribute: valuesArray,
    // filterAttributes = Object.keys(values);

    return `${API}/cities?filters[$and][0][cityName][$containsi]=${values.city}&filters[$and][1][state][country][id][$in]=${values.country}&&filters[$and][1][state][id][$in]=${values.state}&pagination[page]=${current}&pagination[pageSize]=${pageSize}&populate[0]=state&populate[state][populate][0]=country&sort[0]=createdAt:desc`;

    // console.log(query, "check");
  };

  const handelFilterSearch = (filterValues) => {
    // Implement your filtering logic here based on filterValues
    console.log('filter valuesss', filterValues);

    const query = convertToQueryString(filterValues);
    const filteredData = getFilteredData(query);
    // setFilteredData(filteredData);
  };

  const getFilteredData = async (query) => {
    console.log(query, 'query recived');
    try {
      const response = await fetch(`${API}/cities?${query}`, {
        headers: { Authorization: `${BEARER} ${getToken()}` },
      });
      const responseData = await response.json();
      console.log(responseData);
      // if (responseData.error) {
      //   message.error("Unable to fetch Records, please try again later.");
      // } else {
      // }
    } catch (e) {
    } finally {
    }
  };

  const handleFilterChange = (value) => {
    console.log(value, 'fsdflkj ');
  };

  // search table
  const handleSearch = async (event) => {
    console.log(event);
    globalSearch(searchInput);
  };

  const globalSearch = async (searchValue) => {
    if (searchValue === '') {
      fetchData(getToken());
    } else {
      setIsLoading(true);
      let data1 = [];
      try {
        const response = await fetch(
          `${API}/cities?filters[$or][0][cityName][$containsi]=${searchValue}&pagination[page]=1&pagination[pageSize]=${pageSize}&populate[0]=state&populate[state][populate][0]=country&sort[0]=createdAt:desc`,
          {
            headers: { Authorization: `${BEARER} ${getToken()}` },
          }
        );
        const responseData = await response.json();
        if (responseData.error) {
          message.error('Unable to fetch Records, please try again later.');
        } else {
          data1 = responseData.data.map((entry) => {
            console.log(responseData);

            let stateObj = {};
            if (entry.attributes.state.data) {
              stateObj = {
                stateRefId: entry.attributes.state.data.id,
                stateName: entry.attributes.state.data.attributes.stateName,
                countryObj: entry.attributes.state.data.attributes.country.data,
              };
            }

            console.log(stateObj.countryObj);

            let countryObj = {};
            if (stateObj.countryObj) {
              countryObj = {
                countryRefId: stateObj.countryObj.id,
                countryName: stateObj.countryObj.attributes.countryName,
              };
            }

            return {
              id: entry.id,
              cityName: entry.attributes.cityName,
              stateDetails: stateObj,
              countryDetails: countryObj,
            };
          });
          console.log('original Data:', responseData);
          console.log('Data:', data1);
          setData(data1);

          setTotal(responseData.meta.pagination.total);
          setPageSize(responseData.meta.pagination.pageSize);
          setPageCount(responseData.meta.pagination.pageCount);
        }
      } catch (error) {
        console.error(error);
        message.error('Error while fetching Data!');
      } finally {
        setIsLoading(false);
      }
    }
  };
  // Search Table

  //add record to database table by api call
  const addNewRecord = async (data) => {
    setSaving(true);

    //add or removing extra data from values from formData object
    const values = {
      ...data,
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);
    // return;
    //editId will be present only in case of edit mode
    console.log('data edit:', data.data.editId);

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);
      // update the record
      try {
        const response = await fetch(`${API}/cities/${data.data.editId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Update Record, ' + responseData.error);
        } else {
          message.success('Data saved successfully!');

          fetchData(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        dataform.resetFields();
        setEditData(null);
        setIsModalOpen(false);
      }
    } else {
      // create a new record in the database table
      console.log('edit id :', data.editId); //should be falsy

      try {
        const response = await fetch(`${API}/cities/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Record, ' + responseData.error);
        } else {
          message.success('Data saved successfully!');
          //
          fetchData(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        dataform.resetFields();
        setEditData(null);
        setIsModalOpen(false);
      }
    }
  };
  //end function addNewRecord

  // on every page change by the user
  const onPageChange = async (page) => {
    setIsLoading(true);
    console.log(page, pageSize);
    setCurrent(page);

    let data2 = [];
    try {
      const pagin_response = await fetch(
        `${API}/cities?pagination[page]=${page}&pagination[pageSize]=${pageSize}&populate[0]=state&populate[state][populate][0]=country&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${getToken()}` },
        }
      );
      const paginationData = await pagin_response.json();

      if (paginationData.error) {
        message.error('Something went wrong, please try again');
      } else {
        data2 = paginationData.data.map((entry) => {
          console.log(paginationData);

          let stateObj = {};
          if (entry.attributes.state.data) {
            stateObj = {
              stateRefId: entry.attributes.state.data.id,
              stateName: entry.attributes.state.data.attributes.stateName,
              countryObj: entry.attributes.state.data.attributes.country.data,
            };
          }

          console.log(stateObj.countryObj);

          let countryObj = {};
          if (stateObj.countryObj) {
            countryObj = {
              countryRefId: stateObj.countryObj.id,
              countryName: stateObj.countryObj.attributes.countryName,
            };
          }

          return {
            id: entry.id,
            cityName: entry.attributes.cityName,
            stateDetails: stateObj,
            countryDetails: countryObj,
          };
        });
        console.log('paging original Data:', paginationData);
        console.log('paging modified Data:', data2);
        setData(data2);
        // let page = paginationData.meta.pagination.page;

        setTotal(paginationData.meta.pagination.total);
        setPageSize(paginationData.meta.pagination.pageSize);
        setPageCount(paginationData.meta.pagination.pageCount);
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };
  //end function onPageChange

  const columns = [
    {
      key: 'cityName',
      title: 'City',
      dataIndex: 'cityName',
    },
    {
      key: 'state',
      title: 'State',
      render: (record) =>
        record.stateDetails.stateName ? record.stateDetails.stateName : 'NA',
    },
    {
      key: 'country',
      title: 'Country',
      // dataIndex: "country",
      render: (record) =>
        record.countryDetails.countryName
          ? record.countryDetails.countryName
          : 'NA',
    },
    {
      key: 'action',
      title: 'Actions',
      render: (record) => {
        return (
          <div>
            <div className="ActionColumn">
              <EditOutlined
                style={{ color: 'black' }}
                onClick={() => Edit(record)}
              />
              <DeleteOutlined
                style={{ color: 'red' }}
                onClick={() => Delete(record)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const deleteRecord = async (recordID) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/cities/${recordID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        fetchData(getToken());
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const Delete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this',
      onOk: () => {
        deleteRecord(record.id);
      },
    });
  };

  const Edit = (record) => {
    console.log(record);
    setEditData({
      cityName: record.cityName,
      state: record.stateDetails.stateRefId,
      country: record.countryDetails.countryRefId,
      editId: record.id, // its important for edit functionality
    });
    showModal(); // after setting the data for the edit form show the modal
  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log(pagination, filters, sorter);
  };

  return (
    <div>
      <h2>City Master</h2>
      <Button onClick={showModal}>Add New City</Button>
      <div className="FilterSection">
        {/*
        <form onSubmit={handleSearch} className="searchBarr">
          <input
            type="text"
            value={searchInput}
            onChange={(event) => setSearchInput(event.target.value)}
          />
          <button type="submit"> Search</button>
</form>



        <Form form={searchform} layout="vertical" onFinish={handleSearch}>
          <Row gutter={[24]}>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="FilterBy"
                name="filterBy"
                rules={[
                  {
                    required: true,
                    message: "Filter By need to be selected!",
                  },
                ]}
              >
                <Select placeholder="Select " onChange={(e) => {
                  handleFilterChange(e);
                  handleCountryChage();
                }}>
                  {countryList.map((opt) => (
                    <Select.Option value={opt.id} key={opt.id}>
                      {opt.countryName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>

        */}
        <FilterForm
          onFilter={handelFilterSearch}
          optionLists={[countryList, stateListForFilter]}
        />
      </div>
      <div className="table">
        <Table
          dataSource={Data}
          columns={columns}
          pagination={{
            pageSize: pageSize,
            total: total,
            current: current,
            onChange: onPageChange,
          }}
          onChange={handleTableChange}
        />
      </div>
      <Modal
        title="Add New State"
        visible={isModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Card className="addRecord_page_card">
          <Form form={dataform} layout="vertical" onFinish={addNewRecord}>
            <Row gutter={[16, 16]}>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="country"
                  name="country"
                  rules={[
                    {
                      required: true,
                      message: 'Country is Required!',
                    },
                  ]}
                >
                  <Select placeholder="Select " onChange={handleCountryChage}>
                    {countryList.map((opt) => (
                      <Select.Option value={opt.id} key={opt.id}>
                        {opt.countryName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="state"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: 'state is Required!',
                    },
                  ]}
                >
                  <Select placeholder="Select ">
                    {stateList.map((opt) => (
                      <Select.Option value={opt.id} key={opt.id}>
                        {opt.stateName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="City Name"
                  name="cityName"
                  rules={[
                    {
                      required: true,
                      message: 'City Name is Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="City Name" />
                </Form.Item>
              </Col>

              <Form.Item label="editId" hidden={true} name="editId">
                <Input type="text" />
              </Form.Item>
            </Row>

            <Button
              className="addRecord_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {saving ? (
                <>
                  <Spin size="small" /> Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default CityMaster;
