import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
  Space,
  Popconfirm,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import { CSVLink } from 'react-csv';
import { toProperCase } from '../../utils/generic';

const filterGlobalSearch = (Data, searchText, filterScope) => {
  if (!searchText) {
    return Data;
  }

  let filteredDataTemp = [];
  if (filterScope == 'all') {
    filteredDataTemp = Data.filter((value) => {
      console.log(value, 'fab value filter');
      return (
        value?.fabricName?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
        value?.fabricCode?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
        value?.fabricComposition
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase()) ||
        value?.countAndConstruction
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase()) ||
        value?.weight
          ?.toString()
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase()) ||
        value?.fabricPrice
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase()) ||
        value?.inventoryState
          ?.toLowerCase()
          ?.includes(searchText?.toLowerCase())
      );
    });
  } else if (filterScope == 'fabricName') {
    filteredDataTemp = Data.filter((value) => {
      return value?.fabricName
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    });
  } else if (filterScope == 'fabricPrice') {
    filteredDataTemp = Data?.filter((value) => {
      return value?.fabricPrice
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    });
  } else if (filterScope == 'fabricCode') {
    filteredDataTemp = Data?.filter((value) => {
      return value?.fabricCode
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    });
  } else if (filterScope == 'inventoryState') {
    filteredDataTemp = Data.filter((value) => {
      return value?.inventoryState
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase());
    });
  }

  // Disabled: no need to map supplier to fabric
  //  else if (filterScope == 'suppliers') {
  //   filteredDataTemp = Data.filter((value) => {
  //     return value.supplierDetails.supplierName
  //       .toLowerCase()
  //       .includes(searchText.toLowerCase());
  //   });
  // }

  console.log(filteredDataTemp, 'filtered data', filterScope, searchText);
  if (filteredDataTemp.length > 0) {
    return filteredDataTemp;
  } else {
    filteredDataTemp.push({
      key: 0,
      id: 0,
      fabricName: 'Not Found',
      fabricCode: 'Not Found',
      fabricPrice: 'Not Found',
      fabricComposition: 'Not Found',
      countAndConstruction: 'Not Found',
      weight: '0',
      inventoryState: 'Not Found',

      // Disabled: no need to map supplier to fabric
      // supplierDetails: 'Not Found',
    });
    return filteredDataTemp;
  }
};

const FabricMaster = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [saving, setSaving] = useState(false);

  // Edit Data for editing existing record
  const [editData, setEditData] = useState(null);

  const [isLoading, setIsLoading] = useState(null);
  const authToken = getToken();
  const [Data, setData] = useState([]);
  const [dataform] = Form.useForm();
  const [showForm, setShowForm] = useState(false);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [total, setTotal] = useState(0);
  const [unitOfMeasurementList, setUnitOfMeasurement] = useState([]);
  const [fabricGroupList, setFabricGroupList] = useState([]);

  //options for form
  // const [supplierList, setSupplierList] = useState([]);

  const [filterCloumnData, setFilterCloumnData] = useState([]);
  const [filterInfo, setFilterInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState('');

  //filter global
  const [searchText, setSearchText] = useState('');
  const [filterScope, setFilterScope] = useState('all');

  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);
  const filterScopeOptionsArray = [
    'all',
    'fabricName',
    'fabricCode',

    'fabricPrice',
    'inventoryState',
    // Disabled: no need to map supplier to fabric
    // 'suppliers',
  ];

  let data1 = [];

  const fetchData = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/fabrics?populate[0]=suppliers&populate[1]=unit_of_measurement&populate[2]=fabric_group&sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const responseData = await response.json();
      if (responseData.error) {
        if (responseData?.error?.status == 403) {
          message.error(
            'Unauthorized Access, You don’t have permission to perform this action!'
          );
        } else {
          message.error('Something went wrong, please try again');
        }
      } else {
        // return;
        data1 = responseData.data.map((entry) => {
          console.log(
            responseData,
            entry.attributes?.suppliers?.data,
            'supplier check'
          );

          let supplierObj = {};
          if (entry.attributes?.suppliers?.data) {
            supplierObj = {
              supplierRefId: entry.attributes?.suppliers?.data[0]?.id,
              supplierName:
                entry.attributes?.suppliers?.data[0]?.attributes?.supplierName,
            };
          }

          let unit_of_measurementObj = {};
          if (entry.attributes?.unit_of_measurement?.data) {
            unit_of_measurementObj = {
              unit_of_measurementRefId:
                entry.attributes?.unit_of_measurement?.data?.id,
              measurement_name:
                entry.attributes?.unit_of_measurement?.data?.attributes
                  ?.measurement_name,
              measurement_symbol:
                entry.attributes?.unit_of_measurement?.data?.attributes
                  ?.measurement_symbol,
            };
          }

          let fabric_group_object = {};
          if (entry.attributes?.fabric_group?.data) {
            fabric_group_object = {
              fabric_groupRefId: entry.attributes?.fabric_group?.data?.id,
              fabric_group_name:
                entry.attributes?.fabric_group?.data?.attributes
                  ?.fabric_group_name,
            };
          }

          return {
            key: entry.id,
            id: entry.id,
            fabricName: entry.attributes.fabricName,
            fabricCode: entry.attributes.fabricCode,
            fabricPrice: entry.attributes.fabricPrice,
            fabricComposition: entry.attributes.fabricComposition,
            countAndConstruction: entry.attributes.countAndConstruction,
            weight: entry.attributes.weight,
            inventoryState: entry.attributes.inventoryState,
            supplierDetails: supplierObj,
            uomDetails: unit_of_measurementObj,
            fabricGroupDetails: fabric_group_object,
          };
        });

        console.log('original Data:', responseData);
        console.log('current Data after modification:', data1);

        setData(filterGlobalSearch(data1, searchText, filterScope));
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUnitOfMeasurement = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/unit-of-measurements?sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          measurement_name: entry.attributes.measurement_name,
          measurement_symbol: entry.attributes.measurement_symbol,
        };
      });

      setUnitOfMeasurement(optionsData2);
      console.log('original unit of measure Data:', optionsData);
      console.log('modified unit of measure Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchFabricGroup = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/fabric-groups?sort[0]=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          fabric_group_name: entry.attributes.fabric_group_name,
        };
      });

      setFabricGroupList(optionsData2);
      console.log('original Fabric group Data:', optionsData);
      console.log('modified Fabric group Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  // Disabled: no need to map supplier to fabric
  // selecting the dropdowns data
  // const fetchSupplier = async (token) => {
  //   let supplierData2 = [];
  //   setIsLoading(true);
  //   try {
  //     const response = await fetch(`${API}/suppler-masters?`, {
  //       headers: { Authorization: `${BEARER} ${token}` },
  //     });
  //     const responseData = await response.json();
  //     console.log(responseData);
  //     if (responseData.error) {
  //       message.error('Something went wrong, please try again');
  //     } else {
  //       supplierData2 = responseData.data.map((entry) => {
  //         return {
  //           id: entry.id,
  //           supplierName: entry.attributes.supplierName,

  //           // launch_masters: entry.attributes.launch_masters,
  //         };
  //       });

  //       setSupplierList(supplierData2);
  //       console.log('original supplier Data:', responseData);
  //       console.log('modified supplier Data:', supplierData2);
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     message.error('Error while fetching Data!');
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }; // end function fetchSeason

  useEffect(() => {
    if (authToken) {
      fetchData(authToken);
      fetchUnitOfMeasurement(authToken);
      fetchFabricGroup(authToken);
      // Disabled: no need to map supplier to fabric
    }
  }, []);

  // for opening add or edit form with data or change in the reference of data form when form is closed or page is reloaded
  useEffect(() => {
    //dataform data
    console.log(editData);
    dataform.setFieldsValue(editData);
  }, [editData, dataform]);

  // show the modal
  const showModal = () => {
    setIsModalOpen(true);
  };

  // hide and reset add/edit form inside modal
  const handleOk = () => {
    dataform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  // hide and reset add/edit form inside modal
  const handleCancel = () => {
    dataform.resetFields();
    setEditData(null);
    setIsModalOpen(false);
  };

  //add record to database table by api call
  const addNewRecord = async (data) => {
    setSaving(true);

    //add or removing extra data from values from formData object
    const values = {
      ...data,
      weight: parseFloat(data['weight']),
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);
    // return;
    //editId will be present only in case of edit mode
    console.log('data edit:', data.data.editId);

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);
      // update the record
      try {
        const response = await fetch(`${API}/fabrics/${data.data.editId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          if (responseData?.error?.status == 403) {
            message.error(
              'Unauthorized Access, You don’t have permission to perform this action!'
            );
          } else {
            message.error('Unable to Update Record, ' + responseData.error);
          }
        } else {
          message.success('Data saved successfully!');
          fetchData(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        dataform.resetFields();
        setEditData(null);
        setIsModalOpen(false);
      }
    } else {
      // create a new record in the database table
      console.log('edit id :', data.editId); //should be falsy

      try {
        const response = await fetch(`${API}/fabrics/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          if (responseData?.error?.status == 403) {
            message.error(
              'Unauthorized Access, You don’t have permission to perform this action!'
            );
          } else {
            message.error('Unable to Add Record, ' + responseData.error);
          }
        } else {
          message.success('Data saved successfully!');
          //
          fetchData(getToken());
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        dataform.resetFields();
        setEditData(null);
        setIsModalOpen(false);
      }
    }
  };
  //end function addNewRecord

  const columns = [
    {
      key: 'fabricName',
      title: 'Fabric Article Name',
      dataIndex: 'fabricName',
      sorter: (record1, record2) => {
        return record1.fabricName.localeCompare(record2.fabricName);
      },
      sortOrder: sortedInfo.columnKey === 'fabricName' && sortedInfo.order,
    },
    {
      key: 'fabricCode',
      title: 'Fabric Code',
      dataIndex: 'fabricCode',
    },
    {
      key: 'fabricPrice',
      title: 'Fabric Price',
      dataIndex: 'fabricPrice',
    },
    {
      key: 'weight',
      title: 'Weight',
      dataIndex: 'weight',
    },
    {
      key: 'inventoryState',
      title: 'Inventory State',
      dataIndex: 'inventoryState',
    },
    //Disabled: no need to map supplier to fabric
    // {
    //   key: 'suppliers',
    //   title: 'supplier Name',
    //   dataIndex: 'suppliers',
    //   render: (_, record) =>
    //     record.supplierDetails.supplierName
    //       ? record.supplierDetails.supplierName
    //       : 'NA',
    // },
    {
      key: 'action',
      title: 'Actions',

      render: (_, record) => {
        return (
          <div>
            <div className="ActionColumn">
              <EditOutlined
                style={{ color: 'black' }}
                onClick={() => Edit(record)}
              />
              <Button
                icon={
                  <DeleteOutlined style={{ color: 'red', marginRight: 8 }} />
                }
                onClick={() => Delete(record)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const deleteRecord = async (recordID) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/fabrics/${recordID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.error) {
        if (responseData?.error?.status == 403) {
          message.error(
            'Unauthorized Access, You don’t have permission to perform this action!'
          );
        } else {
          message.error('Something went wrong, please try again');
        }
      } else {
        fetchData(getToken());
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const Delete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this',
      onOk: () => {
        deleteRecord(record.id);
      },
    });
  };

  const Edit = (record) => {
    console.log(record);
    // set data for add or edit form
    setEditData({
      fabricName: record?.fabricName,
      fabricPrice: record?.fabricPrice,
      weight: record?.weight?.toString(),
      fabricCode: record.fabricCode,
      fabricComposition: record.fabricComposition,
      countAndConstruction: record.countAndConstruction,
      inventoryState: record.inventoryState,
      unit_of_measurement: record.uomDetails.unit_of_measurementRefId,
      fabric_group: record.fabricGroupDetails.fabric_groupRefId,
      // Disabled: no need to map supplier to fabric
      // suppliers: record.supplierDetails.supplierRefId,
      editId: record.id, // its important for edit functionality
    });
    showModal(); // after setting the data for the edit form show the modal
  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log('in handle table change', pagination, filters, sorter);
    const { order, columnKey } = sorter;
    setFilterInfo(filters);
    setSortedInfo({ columnKey, order });
  };

  //reset table
  const resetTable = () => {
    setSortedInfo({});
    setFilterInfo({});
    setSearchText('');
    setFilterScope('all');
    setAlreadySelectedRows([]);
    fetchData(authToken);
  };

  const handleChangeSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (searchText == '') {
      fetchData(authToken);
    } else {
      fetchData(authToken);
    }
  }, [searchText, filterScope]);

  const handleFilterScopeChange = (value) => {
    console.log('setFilterScope', value);
    setFilterScope(value);
  };

  const getFilterOptionArray = (options) => {
    return options.map((option) => {
      return { value: option, label: option };
    });
  };

  const filterOptionArray = [...getFilterOptionArray(filterScopeOptionsArray)];

  const uomOptionList = unitOfMeasurementList?.map((uom) => {
    return {
      label: toProperCase(uom?.measurement_name),
      value: uom?.id,
    };
  });

  const fabricGroupOptionList = fabricGroupList?.map((fabricGroup) => {
    return {
      label: toProperCase(fabricGroup?.fabric_group_name),
      value: fabricGroup?.id,
    };
  });

  return (
    <div>
      <h2>Fabric Master</h2>
      {/* Modal */}
      <Button onClick={showModal}>Add Fabric</Button>

      {/* Table Section */}
      <div className="table">
        {/* Filter search Section */}
        <div className="filterContainer">
          <div className="tableSearchSection">
            <Select
              defaultValue="all"
              className="filterScopeSelect"
              onChange={handleFilterScopeChange}
              options={filterOptionArray}
            />

            <Input
              placeholder="Enter Search Text"
              onChange={handleChangeSearch}
              type="text"
              allowClear
              value={searchText}
            ></Input>
            <Button onClick={resetTable}>Reset</Button>
            <Button style={{ backgroundColor: 'greenyellow', color: 'white' }}>
              <CSVLink data={Data}>Download</CSVLink>
            </Button>
          </div>
        </div>
        <Table
          dataSource={Data}
          columns={columns}
          onChange={handleTableChange}
          pagination={{ position: ['bottomCenter'] }}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: alreadySelectedRows,
            onChange: (keys) => {
              setAlreadySelectedRows(keys);
            },
            onSelect: (record) => {
              console.log(record, 'selected row ');
            },
            selections: [Table.SELECTION_NONE, Table.SELECTION_ALL],
          }}
        />
      </div>

      {/* Modal For Add Or Edit */}
      <Modal
        title="Add New Fabric"
        visible={isModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Card className="addRecord_page_card">
          <Form form={dataform} layout="vertical" onFinish={addNewRecord}>
            <Row gutter={[16, 16]}>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Fabric Article Name"
                  name="fabricName"
                  rules={[
                    {
                      required: true,
                      message: 'Fabric is Required!',
                    },
                  ]}
                >
                  <Input placeholder="Fabric Article Name" />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Fabric Code"
                  name="fabricCode"
                  rules={[
                    {
                      required: true,
                      message: 'Fabric Code is Required!',
                    },
                  ]}
                >
                  <Input placeholder="Fabric Code" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Fabric Composition"
                  name="fabricComposition"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Fabric Composition" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Count And Construction"
                  name="countAndConstruction"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input.TextArea placeholder="Count And Construction " />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Fabric Price"
                  name="fabricPrice"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a correct value',
                      pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,2}))$/),
                    },
                  ]}
                >
                  <Input placeholder="Fabric Price" />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Weight"
                  name="weight"
                  rules={[
                    {
                      message: 'Please enter a correct value',
                      pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,2}))$/),
                    },
                  ]}
                >
                  <Input placeholder="Enter weight" />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Fresh or Stock"
                  name="inventoryState"
                  rules={[
                    {
                      required: true,
                      message: 'Inventory Status is Required!',
                    },
                  ]}
                >
                  <Select placeholder="Select Inventory State">
                    <Select.Option value="fresh">Fresh</Select.Option>
                    <Select.Option value="stock">Stock</Select.Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Measurement Unit"
                  name="unit_of_measurement"
                  rules={[
                    {
                      required: true,
                      message: 'Measurement Unit is Required!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    onSearch={(value) => {
                      // console.log('searching...', value);
                    }}
                    placeholder="Select "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.trim().toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={uomOptionList}
                  />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Fabric Group"
                  name="fabric_group"
                  rules={[
                    {
                      required: true,
                      message: 'Fabric Group is Required!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    onSearch={(value) => {
                      // console.log('searching...', value);
                    }}
                    placeholder="Select "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.trim().toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={fabricGroupOptionList}
                  />
                </Form.Item>
              </Col>

              {/* Disabled: no need to map supplier to fabric */}
              {/*     <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Suppliers"
                  name="suppliers"
                  rules={[
                    {
                      required: true,
                      message: 'Suppliers is Required!',
                    },
                  ]}
                >
                  <Select placeholder="Select Suppliers">
                    {supplierList.map((opt) => (
                      <Select.Option value={opt.id} key={opt.id}>
                        {opt.supplierName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                    </Col> */}
              <Form.Item label="editId" hidden={true} name="editId">
                <Input type="text" />
              </Form.Item>
            </Row>

            <Button
              className="addRecord_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {saving ? (
                <>
                  <Spin size="small" /> Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};
export default FabricMaster;
