import React from 'react';

import { Tabs } from 'antd';
import CoreProductList from '../CoreProduct/CoreProductList';
import BookedProductList from '../BookedProductList/BookedProductList';
import BookableProductList from '../BookableProductList/BookableProductList';
import ReceivingProductList from '../ReceivingProductList/ReceivingProductList';
import ReceivingProductPendingList from '../ReceivingProductPendingList/ReceivingProductPendingList';
import ReceivingProductReceivedList from '../ReceivingProductReceivedList/ReceivingProductReceivedList';
import { useNavigate, useSearchParams } from 'react-router-dom';

// import PostponedProductsManagemnt from '../PostponedProductsManagemnt/PostponedProductsManagemnt';
// import PostponedProducts from '../PostponedProducts/PostponedProducts';

const { TabPane } = Tabs;
const TabKeyObject = {
  1: 'receivingProduct',
  2: 'receivingProductPending',
  3: 'receivingProductReceived',
};
const InboundProductManagement = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const CurrentPageName = searchParams.get('pageName') || TabKeyObject[1];

  const handleTabChange = (key) => {
    setSearchParams({ pageName: key, page: 1 });
  };

  return (
    <div>
      <div>Incoming Products Management</div>
      <div>
        <Tabs activeKey={CurrentPageName} onChange={handleTabChange}>
          <TabPane tab="Incoming Products" key={TabKeyObject[1]}>
            <ReceivingProductList tabKey={TabKeyObject[1]} />
          </TabPane>
          <TabPane tab="Incoming Pending" key={TabKeyObject[2]}>
            <ReceivingProductPendingList tabKey={TabKeyObject[2]} />
          </TabPane>
          <TabPane tab="Received Products" key={TabKeyObject[3]}>
            <ReceivingProductReceivedList tabKey={TabKeyObject[3]} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};
export default InboundProductManagement;
