import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Select,
} from 'antd';
import { getToken } from '../../helpers';
import { API, BEARER } from '../../constant';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
  RollbackOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  getMonthNameFromStringDate,
  getMonthIndex,
  getDateFromMonth,
  dateFormat,
  monthNames,
} from '../../utils/dateUtils';
import moment from 'moment';
import { getUserDetails } from '../../helpers';

const userRolesAllowedForAttributes = {
  edit: {
    designAttribute: [1000, 1001, 1003],
    merchAttribute: [1000, 1004, 1002],
    planningAttribute: [1000, 1007, 1008],
  },
  view: {},
};

const ProductOptionDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [dataform] = Form.useForm();
  const [editData, setEditData] = useState(null);
  const [fabricList, setfabricList] = useState([]);
  const [launchMastersList, setLaunchMastersList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [indentSheetList, setIndentSheetList] = useState([]);

  const [selectedLaunchNew, setSelectedLaunchNew] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalform] = Form.useForm();

  const [saving, setSaving] = useState(false);

  const [isLoading, setIsLoading] = useState(null);

  const [productOptionDetails, setProductOptionDetails] = useState([]);

  const authToken = getToken();
  const user = getUserDetails();

  //   console.log(location, 'line no 69');
  //   return;

  useEffect(() => {
    console.log(location, 'line no 40');
    if (location.state === null) {
    } else {
      setIsLoading(true);
      //   let productOptionAttributeArray = getProductOptionAttributeArray(
      //     location.state.productOptionDetails
      //   );

      //   console.log(productOptionAttributeArray, '74');
      setEditData({
        month: location.state.month,
        launchDrop: location.state.launchDrop,
        category: location.state.category,
        qty: location.state?.quantity?.toString(),
        assumedMRP: location.state.assumedMRP?.toString(),
        status: location.state.status,
        launch_masters: location.state.launchDetails?.map(
          (launch) => launch.launchRefId
        ),
        product_option_type: location.state.productOptionType.productCatRefId,
        indent_sheets: location.state.indentSheetDetails?.map(
          (indentSheet) => indentSheet.indentRefId
        ),
        editId: location.state.id,
      });
      setIsLoading(false);
      location.state?.launchDetails?.launchRefId &&
        setSelectedLaunchNew(location.state.launchDetails.launchRefId); // for selecting related launch details in the form
    }
    fetchLaunchList(authToken);
    fetchIndentSheetList(authToken);
    fetchProductTypeList(authToken);
  }, []); // setting the required data for the form based on edit / create request.

  useEffect(() => {
    console.log('fields data', editData, dataform);
    dataform.setFieldsValue(editData);
  }, [editData, dataform]); // set form fields on change in editdata or dataform.

  useEffect(() => {
    if (selectedLaunchNew) {
      console.log(selectedLaunchNew, 'SelectedLaunchNew');
    }
  }, [selectedLaunchNew]); // no use useEffect
  if (isLoading) {
    return <Spin size="large" />;
  }

  const getProductOptionAttributeArray = (productOptionDetailObjectArray) => {
    return productOptionDetailObjectArray.map((productOptionObject) => ({
      poqId: productOptionObject.poqId,
      quantity: productOptionObject.quantity,
      product_option_type: productOptionObject.poObject.productCatId,
    }));
  }; // geting nested objects fields in required pattern for easy binding

  const fetchLaunchList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/launch-masters?&populate[season][fields][0]=season_id&createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          launchName: entry.attributes.launchName,
          launchDate: entry.attributes.launchDate,
          launchDrop: entry.attributes.launchDrop,
          //   launchCat: entry.attributes.launchCat,
          launchMonth: entry.attributes.launchMonth,

          seasonName: entry.attributes.season?.data?.attributes.season_id,
          // launch_masters: entry.attributes.launch_masters,
        };
      });

      setLaunchMastersList(optionsData2);
      console.log('original option Data:', optionsData);
      console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProductTypeList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/product-option-types?createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          productCategoryName: entry.attributes.productCategoryName,
        };
      });

      setProductTypeList(optionsData2);
      console.log('original Product Type Data:', optionsData);
      console.log('modified Product type Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchIndentSheetList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/indent-sheets?sort=createdAt:desc`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          Indent_file_reference_id: entry.attributes.Indent_file_reference_id,
        };
      });

      setIndentSheetList(optionsData2);
      console.log('original indent sheet Data:', optionsData);
      console.log('modified indent sheet Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const addNewRecord = async (data) => {
    setSaving(true);

    const assumedMRP = parseFloat(data['assumedMRP']);
    const qty = parseInt(data['qty']);
    console.log(data);
    const values = {
      assumedMRP,
      qty,
      ...data,
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);

    // return;
    console.log('data edit:', data.data.editId);

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);

      try {
        const response = await fetch(
          `${API}/product-options/${data.data.editId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log('result recived', responseData);
        if (responseData.error) {
          message.error('Unable to Update Record, ' + responseData.error);
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        setEditData(null);
      }
    } else {
      // create a new record in the database table
      console.log('edit id :', data.editId);

      try {
        const response = await fetch(`${API}/product-options/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  const toPreviousPage = () => {
    navigate(-1);
  };

  const launchListOptions = launchMastersList.map((launch) => {
    return {
      label: launch.launchName,
      value: launch.id,
    };
  });

  const monthListOptions = monthNames.map((month, index) => {
    return {
      label: month,
      value: month,
    };
  });

  const ProductTypeOptions = productTypeList.map((productType) => {
    return {
      label: productType.productCategoryName,
      value: productType.id,
    };
  });
  const IndentSheetList = indentSheetList.map((indentSheet) => {
    // console.log(indentSheet, 'line no 339');
    return {
      label: indentSheet.Indent_file_reference_id,
      value: indentSheet.id,
    };
  });

  // ==============
  const onFinish = async (data) => {
    console.log('Received values of form:', data);
    setSaving(true);
    const values = {
      ...data,
      // month: getDateFromMonth(data.month),
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);

    // return;
    console.log('data edit:', data.data.editId);

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);

      try {
        const response = await fetch(
          `${API}/product-options/${data.data.editId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log('result recived', responseData);
        if (responseData.error) {
          message.error('Unable to Update Record, ' + responseData.error);
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        setEditData(null);
      }
    } else {
      // create a new record in the database table
      console.log('edit id :', data.editId);

      try {
        const response = await fetch(`${API}/product-options/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  //** For multiple Launch Selection this Code can be used */
  // const updateDependentField = (value) => {
  //   let selectedLaunchArray = [];

  //   console.log('Updating', value, launchMastersList);
  //   value.map((launchId) => {
  //     const selectedLaunch = launchMastersList.filter((launch) => {
  //       return launch.id === launchId && launch;
  //     });
  //     selectedLaunchArray.push(...selectedLaunch);
  //   });

  //   console.log(selectedLaunchArray);
  //   setEditData({
  //     ...editData,
  //     launch_masters: selectedLaunchArray.map((launch) => launch.id),
  //     launchDrop: selectedLaunchArray[0]?.launchDrop,
  //     month: selectedLaunchArray[0]?.launchMonth,
  //   });
  // };

  //**  For single Launch Selection this code can be used */
  const updateDependentField = (value) => {
    console.log('Updating', value, launchMastersList);

    const selectedLaunch = launchMastersList.filter((launch) => {
      return launch.id === value && launch;
    });

    console.log(selectedLaunch);
    setEditData({
      ...editData,
      launch_masters: selectedLaunch.map((launch) => launch.id),
      launchDrop: selectedLaunch[0]?.launchDrop,
      month: selectedLaunch[0]?.launchMonth,
    });
  };

  const removeExtraField = (index) => {
    const updatedProductOptionDetails = [...productOptionDetails];
    updatedProductOptionDetails.splice(index, 1);
    setProductOptionDetails(updatedProductOptionDetails);
  }; // not used

  const showModal = () => {
    setIsModalOpen(true);
    // console.log(
    //   dataform.getFieldValue('launch_masters'),
    //   dataform.getFieldValue('launch_masters')[0]
    // );
    modalform.setFieldsValue({
      launch_master: dataform.getFieldValue('launch_masters')[0] ?? null,
      indent_file_date: moment(),
    });
  };

  const handleOk = () => {
    modalform.resetFields();
    // setEditData(null);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    modalform.resetFields();
    // setEditData(null);
    setIsModalOpen(false);
  };

  const addNewEntity = async (data) => {
    setSaving(true);
    const values = {
      ...data,
      product_options: editData?.editId ? editData.editId : null,
      launch_master: editData?.launch_masters[0]
        ? editData?.launch_masters[0]
        : null,
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);

    // return;
    console.log('data edit:', data.data.editEntityId);

    if (data.data.editEntityId) {
      console.log('edit id modal entity :', data.data.editEntityId);

      try {
        const response = await fetch(
          `${API}/indent-sheets/${data.data.editEntityId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log('result recived', responseData);
        if (responseData.error) {
          message.error('Unable to Update Record, ' + responseData.error);
        } else {
          message.success('Data saved successfully!');
          fetchIndentSheetList(authToken);

          //   toPreviousPage();
          handleOk();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        setEditData(null);
      }
    } else {
      // create a new record in the database table
      //   console.log('edit id :', data.editEntityId);

      try {
        const response = await fetch(`${API}/indent-sheets/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData, 'created Indent Sheet', editData, [
          ...dataform?.getFieldValue('indent_sheets'),
          responseData?.data?.id,
        ]);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          fetchIndentSheetList(authToken);
          dataform.setFieldsValue({
            indent_sheets: [
              ...dataform?.getFieldValue('indent_sheets'),
              responseData?.data?.id,
            ],
          });
          handleOk();
          //   toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <div>
      <div>Product Option Details</div>

      <Button type="link" onClick={toPreviousPage}>
        Back
        <RollbackOutlined />
      </Button>

      <div>
        <Form
          form={dataform}
          layout="vertical"
          onFinish={addNewRecord}
          disabled={editData?.status === 'core_prod_generated'}
        >
          <Row gutter={[16, 16]}>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Launch Master"
                name="launch_masters"
                rules={[
                  {
                    required: true,
                    message: 'Launch is Required!',
                  },
                ]}
              >
                <Select
                  showSearch
                  onSearch={(value) => {
                    // console.log('searching...', value);
                  }}
                  onChange={updateDependentField}
                  placeholder="Select "
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.trim().toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={launchListOptions}
                  // mode="multiple"
                />
              </Form.Item>
            </Col>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Month"
                name="month"
                rules={[
                  {
                    required: true,
                    message: 'Month is Required!',
                  },
                ]}
              >
                {<Select options={monthListOptions} />}
                {/*<Input placeholder="Month" /> */}
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Product Option Type/Sub Category"
                name="product_option_type"
                rules={[
                  {
                    required: true,
                    message: 'Product Sub Category is Required!',
                  },
                ]}
              >
                <Select
                  showSearch
                  onSearch={(value) => {
                    // console.log('searching...', value);
                  }}
                  placeholder="Select "
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.trim().toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={ProductTypeOptions}
                />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Quantity"
                name="qty"
                rules={[
                  {
                    message: 'Please enter a valid Quantity',
                    pattern: '^([0-9]{0,10})$',
                  },
                ]}
              >
                <Input
                  placeholder="Quantity"
                  disabled={
                    !user.rolesAssigned.find((role) =>
                      userRolesAllowedForAttributes.edit.planningAttribute.includes(
                        role
                      )
                    )
                  }
                />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Status" name="status" initialValue="active">
                <Select placeholder="Select Status">
                  <Select.Option value="active">Active</Select.Option>
                  <Select.Option value="inactive">In Active</Select.Option>
                  <Select.Option value="wip">WIP</Select.Option>
                  <Select.Option value="droped">Droped</Select.Option>
                  <Select.Option value="complete">complete</Select.Option>
                  <Select.Option value="confirmed">Confirmed</Select.Option>
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Assumed MRP"
                name="assumedMRP"
                rules={[
                  {
                    message: 'Please enter a valid Quantity',
                    pattern: new RegExp(/^([0-9]{0,10}((.)[0-9]{0,2}))$/),
                  },
                ]}
              >
                <Input
                  placeholder="Enter Assumed MRP"
                  disabled={
                    !user.rolesAssigned.find(
                      (role) =>
                        userRolesAllowedForAttributes.edit.planningAttribute.includes(
                          role
                        ) ||
                        userRolesAllowedForAttributes.edit.designAttribute.includes(
                          role
                        )
                    )
                  }
                />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <div className="addNewMasterNSelect">
                <Form.Item
                  label="Indent Sheets"
                  name="indent_sheets"
                  className="SelectEntity"
                >
                  <Select
                    mode="multiple"
                    showSearch
                    onSearch={(value) => {
                      // console.log('searching...', value);
                    }}
                    placeholder="Select "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.trim().toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={IndentSheetList}
                  />
                </Form.Item>
                <PlusOutlined
                  width={'20px'}
                  onClick={showModal}
                  className="addIconForNewEntity"
                />
              </div>
            </Col>
          </Row>

          {/* hidden items*/}
          <Form.Item hidden={true} name="editId">
            <Input type="text" />
          </Form.Item>
          <Form.Item hidden={true} name="launchDrop">
            <Input type="text" />
          </Form.Item>
          <Form.Item hidden={true} name="category">
            <Input type="text" />
          </Form.Item>
          {/* hidden items*/}
          <Button
            className="addProduct_save_btn"
            htmlType="submit"
            type="primary"
            size="large"
          >
            {saving ? (
              <>
                <Spin size="small" /> Saving
              </>
            ) : (
              'Save'
            )}
          </Button>
        </Form>
      </div>
      <Modal
        title="Add New Indent Sheet"
        visible={isModalOpen}
        okButtonProps={{ style: { display: 'none' } }}
        cancelButtonProps={{ style: { display: 'none' } }}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        <Card className="addProduct_page_card">
          <Form form={modalform} layout="vertical" onFinish={addNewEntity}>
            <Row gutter={[16, 16]}>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Indent Sheet Link"
                  name="Indent_file_link"
                  rules={[
                    {
                      required: true,
                      message: 'Indent Sheet Link is Required!',
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Indent Sheet URL" />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Indent File Date"
                  name="indent_file_date"
                  rules={[
                    {
                      required: true,
                      message: 'Indent Sheet Date is Required!',
                      type: 'object',
                    },
                  ]}
                >
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </Col>
              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Indent Reference"
                  name="Indent_file_reference_id"
                  rules={[
                    {
                      type: 'string',
                    },
                  ]}
                >
                  <Input placeholder="Indent Reference ID" />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item name="indent_type" label="Indent Type">
                  <Select
                    placeholder="Select Indent Type"
                    options={[
                      { value: 'Print', label: 'Print' },
                      { value: 'Fabric', label: 'Fabric' },
                    ]}
                  />
                </Form.Item>
              </Col>

              <Col md={8} lg={8} sm={24} xs={24}>
                <Form.Item
                  label="Launch Master"
                  name="launch_master"
                  rules={[
                    {
                      required: true,
                      message: 'Launch is Required!',
                    },
                  ]}
                >
                  <Select
                    showSearch
                    onSearch={() => {
                      //
                    }}
                    placeholder="Select "
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? '')
                        .toLowerCase()
                        .includes(input.trim().toLowerCase())
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? '')
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={launchListOptions}
                  />
                </Form.Item>
              </Col>

              <Form.Item label="Username" hidden={true} name="editEntityId">
                <Input type="text" />
              </Form.Item>
            </Row>
            <Button
              className="addProduct_save_btn"
              htmlType="submit"
              type="primary"
              size="large"
            >
              {saving ? (
                <>
                  <Spin size="small" /> Saving
                </>
              ) : (
                'Save'
              )}
            </Button>
          </Form>
        </Card>
      </Modal>
    </div>
  );
};

export default ProductOptionDetails;
