import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Select,
  Typography,
} from 'antd';
import { getToken } from '../../helpers';
import { API, BEARER } from '../../constant';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
  RollbackOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  getMonthNameFromStringDate,
  getMonthIndex,
  getDateFromMonth,
  dateFormat,
  monthNames,
  delay,
} from '../../utils/dateUtils';
import moment from 'moment';

const VariantProductDetails = (props) => {
  console.log(props);
  const navigate = useNavigate();
  const location = useLocation();

  const [dataform] = Form.useForm();
  const [editData, setEditData] = useState(null);
  const [fabricList, setfabricList] = useState([]);
  const [launchMastersList, setLaunchMastersList] = useState([]);
  const [productTypeList, setProductTypeList] = useState([]);
  const [indentSheetList, setIndentSheetList] = useState([]);
  const [selectedLaunchNew, setSelectedLaunchNew] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalform] = Form.useForm();

  const [saving, setSaving] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [productOptionDetails, setProductOptionDetails] = useState([]);

  const [productVariantDetails, setProductVariantDetails] = useState(null);

  const authToken = getToken();

  //   console.log(location, 'line no 57');
  //   return;

  useEffect(() => {
    setIsLoading(true);
    console.log(location, 'line no 61');
    if (location.state === null) {
    } else {
      getProductVariantAttributeArray(location.state.variantId);

      //   console.log(productVariantDetails, '74');
      //   setEditData({
      //     month: location.state.month,
      //     launchDrop: location.state.launchDrop,
      //     category: location.state.category,
      //     qty: location.state.quantity?.toString(),
      //     assumedMRP: location.state.assumedMRP,
      //     status: location.state.status,
      //     launch_masters: location.state.launchDetails.map(
      //       (launch) => launch.launchRefId
      //     ),
      //     product_option_type: location.state.productOptionType.productCatRefId,
      //     indent_sheets: location.state.indentSheetDetails.map(
      //       (indentSheet) => indentSheet.indentRefId
      //     ),
      //     editId: location.state.id,
      //   });

      //   location.state?.launchDetails?.launchRefId &&
      //     setSelectedLaunchNew(location.state.launchDetails.launchRefId); // for selecting related launch details in the form
    }
    // fetchLaunchList(authToken);
    // fetchIndentSheetList(authToken);
    // fetchProductTypeList(authToken);
    setIsLoading(false);
  }, []); // setting the required data for the form based on edit / create request.

  useEffect(() => {
    setIsLoading(true);
    console.log(productVariantDetails, 'new product variant details');
    setEditData({
      cost: productVariantDetails?.data?.attributes?.cost?.toString(),
      mrp: productVariantDetails?.data?.attributes?.mrp?.toString(),
      variant_sku: productVariantDetails?.data?.attributes?.variant_sku,
      editId: productVariantDetails?.data?.id,
    });
    setIsLoading(false);
  }, [productVariantDetails]);

  useEffect(() => {
    setIsLoading(true);
    console.log(editData);
    dataform.setFieldsValue(editData);
    setIsLoading(false);
  }, [editData, dataform]); // set form fields on change in editdata or dataform.

  //   useEffect(() => {
  //     if (selectedLaunchNew) {
  //       console.log(selectedLaunchNew, 'SelectedLaunchNew');
  //     }
  //   }, [selectedLaunchNew]); // no use useEffect
  if (isLoading) {
    return <Spin size="large" />;
  }

  const getProductVariantAttributeArray = async (variantId) => {
    // await delay(5000);
    setIsLoading(true);
    const variantDetails = {};
    try {
      const response = await fetch(
        `${API}/product-variants/${variantId}?&populate=*`,
        {
          headers: { Authorization: `${BEARER} ${authToken}` },
        }
      );
      const variantData = await response.json();

      Object.entries(variantData).forEach(
        ([key, value]) => (variantDetails[key] = value)
      );

      console.log(variantDetails, 'variant data');
      setProductVariantDetails(variantDetails);
      //   return { ...variantDetails?.data };

      //   return {
      //     variantCost: entry.id,
      //     launchName: entry.attributes.launchName,
      //     launchDate: entry.attributes.launchDate,
      //     launchDrop: entry.attributes.launchDrop,
      //     //   launchCat: entry.attributes.launchCat,
      //     launchMonth: entry.attributes.launchMonth,

      //     seasonName: entry.attributes.season?.data?.attributes.season_id,
      //     // launch_masters: entry.attributes.launch_masters,
      //   };

      //   console.log('original option Data:', optionsData);
      //   console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  }; // geting nested objects fields in required pattern for easy binding

  const fetchLaunchList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/launch-masters?&populate[season][fields][0]=season_id&createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          launchName: entry.attributes.launchName,
          launchDate: entry.attributes.launchDate,
          launchDrop: entry.attributes.launchDrop,
          //   launchCat: entry.attributes.launchCat,
          launchMonth: entry.attributes.launchMonth,

          seasonName: entry.attributes.season?.data?.attributes.season_id,
          // launch_masters: entry.attributes.launch_masters,
        };
      });

      setLaunchMastersList(optionsData2);
      console.log('original option Data:', optionsData);
      console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProductTypeList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/product-option-types?createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          productCategoryName: entry.attributes.productCategoryName,
        };
      });

      setProductTypeList(optionsData2);
      console.log('original Product Type Data:', optionsData);
      console.log('modified Product type Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchIndentSheetList = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/indent-sheets?sort=createdAt:desc`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          Indent_file_reference_id: entry.attributes.Indent_file_reference_id,
        };
      });

      setIndentSheetList(optionsData2);
      console.log('original indent sheet Data:', optionsData);
      console.log('modified indent sheet Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const addNewRecord = async (data) => {
    setSaving(true);

    console.log(data);
    const values = {
      ...data,
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);

    // return;
    console.log('data edit:', data.data.editId);

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);

      try {
        const response = await fetch(
          `${API}/product-variants/${data.data.editId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log('result recived', responseData);
        if (responseData.error) {
          message.error('Unable to Update Record, ' + responseData.error);
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        setEditData(null);
      }
    } else {
      // create a new record in the database table
      console.log('edit id :', data.editId);

      try {
        const response = await fetch(`${API}/product-variants/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  const deleteProductVariant = async (data) => {
    console.log(data, editData, editData.editId, 336);

    if (editData.editId) {
      setSaving(true);
      console.log('delete id :', editData.editId);

      try {
        const response = await fetch(
          `${API}/product-variants/${editData.editId}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${authToken}`,
            },
          }
        );
        const responseData = await response.json();
        console.log('DELETE REQUEST RESULT', responseData);
        if (responseData.error) {
          message.error('Unable to Delete Record.');
        } else {
          message.success('Deleted successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Processing the Request!');
      } finally {
        setSaving(false);
        setEditData(null);
      }
    } else {
      message.error(
        'Invalid Request - Required data missing, Please try again later!'
      );
    }
  };

  const toPreviousPage = () => {
    navigate(-1);
  };

  const launchListOptions = launchMastersList.map((launch) => {
    return {
      label: launch.launchName,
      value: launch.id,
    };
  });

  const monthListOptions = monthNames.map((month, index) => {
    return {
      label: month,
      value: month,
    };
  });

  const ProductTypeOptions = productTypeList.map((productType) => {
    return {
      label: productType.productCategoryName,
      value: productType.id,
    };
  });
  const IndentSheetList = indentSheetList.map((indentSheet) => {
    // console.log(indentSheet, 'line no 339');
    return {
      label: indentSheet.Indent_file_reference_id,
      value: indentSheet.id,
    };
  });

  // ==============
  const onFinish = async (data) => {
    console.log('Received values of form:', data);
    setSaving(true);
    const values = {
      ...data,
      // month: getDateFromMonth(data.month),
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);

    // return;
    console.log('data edit:', data.data.editId);

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);

      try {
        const response = await fetch(
          `${API}/product-options/${data.data.editId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log('result recived', responseData);
        if (responseData.error) {
          message.error('Unable to Update Record, ' + responseData.error);
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        setEditData(null);
      }
    } else {
      // create a new record in the database table
      console.log('edit id :', data.editId);

      try {
        const response = await fetch(`${API}/product-options/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  //** For multiple Launch Selection this Code can be used */
  // const updateDependentField = (value) => {
  //   let selectedLaunchArray = [];

  //   console.log('Updating', value, launchMastersList);
  //   value.map((launchId) => {
  //     const selectedLaunch = launchMastersList.filter((launch) => {
  //       return launch.id === launchId && launch;
  //     });
  //     selectedLaunchArray.push(...selectedLaunch);
  //   });

  //   console.log(selectedLaunchArray);
  //   setEditData({
  //     ...editData,
  //     launch_masters: selectedLaunchArray.map((launch) => launch.id),
  //     launchDrop: selectedLaunchArray[0]?.launchDrop,
  //     month: selectedLaunchArray[0]?.launchMonth,
  //   });
  // };

  //**  For single Launch Selection this code can be used */
  const updateDependentField = (value) => {
    console.log('Updating', value, launchMastersList);

    const selectedLaunch = launchMastersList.filter((launch) => {
      return launch.id === value && launch;
    });

    console.log(selectedLaunch);
    setEditData({
      ...editData,
      launch_masters: selectedLaunch.map((launch) => launch.id),
      launchDrop: selectedLaunch[0]?.launchDrop,
      month: selectedLaunch[0]?.launchMonth,
    });
  };

  const removeExtraField = (index) => {
    const updatedProductOptionDetails = [...productOptionDetails];
    updatedProductOptionDetails.splice(index, 1);
    setProductOptionDetails(updatedProductOptionDetails);
  }; // not used

  const showModal = () => {
    setIsModalOpen(true);
    // console.log(
    //   dataform.getFieldValue('launch_masters'),
    //   dataform.getFieldValue('launch_masters')[0]
    // );
    modalform.setFieldsValue({
      launch_master: dataform.getFieldValue('launch_masters')[0] ?? null,
      indent_file_date: moment(),
    });
  };

  const handleOk = () => {
    modalform.resetFields();
    // setEditData(null);
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    modalform.resetFields();
    // setEditData(null);
    setIsModalOpen(false);
  };

  const addNewEntity = async (data) => {
    setSaving(true);
    const values = {
      ...data,
      product_options: editData?.editId ? editData.editId : null,
      launch_master: editData?.launch_masters[0]
        ? editData?.launch_masters[0]
        : null,
    };

    data = { data: values };

    console.log('data:', data);
    console.log('values:', values);

    // return;
    console.log('data edit:', data.data.editEntityId);

    if (data.data.editEntityId) {
      console.log('edit id modal entity :', data.data.editEntityId);

      try {
        const response = await fetch(
          `${API}/indent-sheets/${data.data.editEntityId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',

              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify(data),
          }
        );
        const responseData = await response.json();
        console.log('result recived', responseData);
        if (responseData.error) {
          message.error('Unable to Update Record, ' + responseData.error);
        } else {
          message.success('Data saved successfully!');
          fetchIndentSheetList(authToken);
          //   toPreviousPage();
          handleOk();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        setEditData(null);
      }
    } else {
      // create a new record in the database table
      //   console.log('edit id :', data.editEntityId);

      try {
        const response = await fetch(`${API}/indent-sheets/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          message.error('Unable to Add Product Specification.');
        } else {
          message.success('Data saved successfully!');
          fetchIndentSheetList(authToken);
          handleOk();
          //   toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <div>
      {!productVariantDetails ? (
        <Spin size="large" />
      ) : (
        <>
          <Typography.Title level={2}>Variants Details</Typography.Title>
          {/* <div>Variant Details</div> */}
          <div className="backButtonContainer">
            <Button type="link" onClick={toPreviousPage}>
              Back
              <RollbackOutlined />
            </Button>
          </div>
          <div>
            <Form
              form={dataform}
              layout="vertical"
              onFinish={addNewRecord}
              disabled={false}
            >
              <Row gutter={[16, 16]}>
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="variant_sku"
                    name="variant_sku"
                    rules={[
                      {
                        required: true,
                        message: 'Variant SKU is Required!',
                      },
                    ]}
                  >
                    <Input placeholder="Variant SKU" />
                  </Form.Item>
                </Col>

                {/*<Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Month"
                name="month"
                rules={[
                  {
                    required: true,
                    message: 'Month is Required!',
                  },
                ]}
              >
                {<Select options={monthListOptions} />}
                
              </Form.Item>
            </Col>*/}

                {/*<Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Product Option Type/Category"
                name="product_option_type"
                rules={[
                  {
                    required: true,
                    message: 'product Option Type is Required!',
                  },
                ]}
              >
                <Select
                  showSearch
                  onSearch={(value) => {
                    // console.log('searching...', value);
                  }}
                  placeholder="Select "
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.trim().toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={ProductTypeOptions}
                />
              </Form.Item>
            </Col>
                */}
                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="Cost"
                    name="cost"
                    rules={[
                      {
                        message: 'Please enter a valid Cost',
                        pattern: '^([0-9]{0,10}((.)[0-9]{0,4}))$',
                      },
                    ]}
                  >
                    <Input placeholder="Cost" />
                  </Form.Item>
                </Col>

                <Col md={8} lg={8} sm={24} xs={24}>
                  <Form.Item
                    label="MRP"
                    name="mrp"
                    rules={[
                      {
                        required: true,
                        message: 'MRP is Required!',
                      },
                    ]}
                  >
                    <Input placeholder="Enter MRP" />
                  </Form.Item>
                </Col>
              </Row>

              {/* hidden items*/}
              <Form.Item hidden={true} name="editId">
                <Input type="text" />
              </Form.Item>

              {/* hidden items*/}

              <div className="infoPage_actionButton">
                <Button
                  className="addProduct_save_btn"
                  htmlType="submit"
                  type="primary"
                  size="large"
                >
                  {saving ? (
                    <>
                      <Spin size="small" /> Saving
                    </>
                  ) : (
                    'Save'
                  )}
                </Button>

                <Button
                  className="addProduct_del_btn"
                  onClick={deleteProductVariant}
                  type="danger"
                  size="large"
                >
                  {saving ? (
                    <>
                      <Spin size="small" /> Deleting
                    </>
                  ) : (
                    'Delete'
                  )}
                </Button>
              </div>
            </Form>
          </div>
          <Modal
            title="Add New Indent Sheet"
            visible={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            width={1000}
          >
            <Card className="addProduct_page_card">
              <Form form={modalform} layout="vertical" onFinish={addNewEntity}>
                <Row gutter={[16, 16]}>
                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Indent Sheet Link"
                      name="Indent_file_link"
                      rules={[
                        {
                          required: true,
                          message: 'Indent Sheet Link is Required!',
                          type: 'string',
                        },
                      ]}
                    >
                      <Input placeholder="Indent Sheet URL" />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Indent File Date"
                      name="indent_file_date"
                      rules={[
                        {
                          required: true,
                          message: 'Indent Sheet Date is Required!',
                          type: 'object',
                        },
                      ]}
                    >
                      <DatePicker format={dateFormat} />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Indent Reference"
                      name="Indent_file_reference_id"
                      rules={[
                        {
                          type: 'string',
                        },
                      ]}
                    >
                      <Input placeholder="Indent Reference ID" />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item name="indent_type" label="Indent Type">
                      <Select
                        placeholder="Select Indent Type"
                        options={[
                          { value: 'Print', label: 'Print' },
                          { value: 'Fabric', label: 'Fabric' },
                        ]}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8} sm={24} xs={24}>
                    <Form.Item
                      label="Launch Master"
                      name="launch_master"
                      rules={[
                        {
                          required: true,
                          message: 'Launch is Required!',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        onSearch={() => {
                          //
                        }}
                        placeholder="Select "
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.trim().toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? '')
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? '').toLowerCase())
                        }
                        options={launchListOptions}
                      />
                    </Form.Item>
                  </Col>

                  <Form.Item label="Username" hidden={true} name="editEntityId">
                    <Input type="text" />
                  </Form.Item>
                </Row>
                <Button
                  className="addProduct_save_btn"
                  htmlType="submit"
                  type="primary"
                  size="large"
                >
                  {saving ? (
                    <>
                      <Spin size="small" /> Saving
                    </>
                  ) : (
                    'Save'
                  )}
                </Button>
              </Form>
            </Card>
          </Modal>
        </>
      )}
    </div>
  );
};

export default VariantProductDetails;
