import React from 'react';

import { Tabs } from 'antd';
import CoreProductList from '../CoreProduct/CoreProductList';
import BookedProductList from '../BookedProductList/BookedProductList';
import BookableProductList from '../BookableProductList/BookableProductList';
import { useNavigate, useSearchParams } from 'react-router-dom';

// import PostponedProductsManagemnt from '../PostponedProductsManagemnt/PostponedProductsManagemnt';
// import PostponedProducts from '../PostponedProducts/PostponedProducts';

const { TabPane } = Tabs;

const TabKeyObject = {
  1: 'coreProductList',
  2: 'bookableProductList',
  3: 'bookedProductList',
};

const ProductManagement = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const CurrentPageName = searchParams.get('pageName') || TabKeyObject[1];

  const handleTabChange = (key) => {
    setSearchParams({ pageName: key, page: 1 });
  };

  return (
    <div>
      <div> Product Management Portals</div>
      <div>
        <Tabs activeKey={CurrentPageName} onChange={handleTabChange}>
          <TabPane tab="Core Product" key={TabKeyObject[1]}>
            <CoreProductList tabKey={TabKeyObject[1]} />
          </TabPane>
          <TabPane tab="Bookable Products" key={TabKeyObject[2]}>
            <BookableProductList tabKey={TabKeyObject[2]} />
          </TabPane>
          <TabPane tab="Booked Products" key={TabKeyObject[3]}>
            <BookedProductList tabKey={TabKeyObject[3]} />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default ProductManagement;
