import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  DatePicker,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import { getToken } from '../../helpers';
import { API, BEARER } from '../../constant';
import { useEffect, useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { RollbackOutlined } from '@ant-design/icons';

const CompleteProductView = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [productform] = Form.useForm();
  const [saving, setSaving] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [optionList, setOptionList] = useState([]);
  const [optionListCollection, setOptionListCollection] = useState([]);

  const authToken = getToken();

  const fetchOptions = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/launch-masters?&populate[season][fields][0]=season_id`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          launchName: entry.attributes.launchName,
          launchDate: entry.attributes.launchDate,
          seasonName: entry.attributes.season?.data?.attributes.season_id,
          // launch_masters: entry.attributes.launch_masters,
        };
      });

      setOptionList(optionsData2);
      console.log('original option Data:', optionsData);
      console.log('modified Data:', optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCollectionOptions = async (token) => {
    let optionsData2 = [];
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/product-collections`, {
        headers: { Authorization: `${BEARER} ${token}` },
      });
      const optionsData = await response.json();

      optionsData2 = optionsData.data.map((entry) => {
        return {
          id: entry.id,
          collectionName: entry.attributes.collectionName,
          onlineCollectionName: entry.attributes.onlineCollectionName,
          onlineCollectionId: entry.attributes.onlineCollectionId,
          onlineCollectionId: entry.attributes.description,
        };
      });

      setOptionListCollection(optionsData2);
      console.log('original option Data:', optionsData);
      // console.log("modified Data:", optionsData2);
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log(location);

    setEditData({
      SKU: location.state.SKU,
      productName: location.state.productName,
      websiteProductName: location.state.websiteProductName,
      Color: location.state.Color,
      atfContent: location.state.atfContent,
      attributionCollection: location.state.attributionCollection,
      attributionColor: location.state.attributionColor,
      btfReferenceLinks: location.state.btfReferenceLinks,
      crossSells: location.state.crossSells,
      design: location.state.design,
      fabricDescription: location.state.fabricDescription,
      feature1: location.state.feature1,
      feature2: location.state.feature2,
      feature3: location.state.feature3,
      fit: location.state.fit,
      lifeOccasion: location.state.lifeOccasion,
      modelInfo: location.state.modelInfo,
      pattern: location.state.pattern,
      price: location.state.price?.toString(),
      season: location.state.season,
      seoDescription: location.state.seoDescription,
      silhouette: location.state.silhouette,
      specialAttribute: location.state.specialAttribute,
      suggestedStyle: location.state.suggestedStyle,
      trend: location.state.trend,
      launch_masters: location.state.launchDetails?.map(
        (launch) => launch.launchRefId
      ),
      product_collections: location.state.productCollectionDetails?.map(
        (productCollection) => productCollection.productCollectionRefId
      ),
      editId: location.state.id,
    });
    fetchOptions(authToken);
    fetchCollectionOptions(authToken);
  }, []);

  useEffect(() => {
    console.log(editData);
    productform.setFieldsValue(editData);
  }, [editData, productform]);

  const toPreviousPage = () => {
    navigate(-1);
  };

  if (isLoading) {
    return <Spin size="large" />;
  }

  const addNewProductSpecification = async (data) => {
    setSaving(true);

    const values = {
      ...data,
    };

    data = { data: values };

    console.log('data:', data);
    console.log('data edit:', data.data.editId);
    // return;

    if (data.data.editId) {
      console.log('edit id :', data.data.editId);

      try {
        const response = await fetch(`${API}/products/${data.data.editId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          // message.error('Unable to Update Products Details.');
          message.error('Unable to Update Record, ' + responseData.error);
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
        // setEditData(null);
      }
    } else {
      console.log('edit id :', data.editId);

      try {
        const response = await fetch(`${API}/products/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',

            Authorization: `Bearer ${getToken()}`,
          },
          body: JSON.stringify(data),
        });
        const responseData = await response.json();
        console.log(responseData);
        if (responseData.error) {
          // message.error('Unable to Add Product Details.');
          message.error('Unable to Add Record, ' + responseData.error);
        } else {
          message.success('Data saved successfully!');
          toPreviousPage();
        }
      } catch (error) {
        console.error(Error);
        message.error('Error While Updating the Data!');
      } finally {
        setSaving(false);
      }
    }
  };

  return (
    <div>
      <div>Final Product View</div>

      <Button type="link" onClick={toPreviousPage}>
        Back
        <RollbackOutlined />
      </Button>

      <div>
        <Form
          form={productform}
          layout="vertical"
          onFinish={addNewProductSpecification}
        >
          <Row gutter={[16, 16]}>
            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="SKU"
                name="SKU"
                rules={[
                  {
                    required: true,
                    message: 'SKU is Required!',
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="SKU" disabled={true} />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Product Name"
                name="productName"
                rules={[
                  {
                    required: true,
                    message: 'Product Name is Required!',
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Product Name" value={editData?.styleNo} />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Website Product Name"
                name="websiteProductName"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="websiteProductName" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Color"
                name="Color"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Color" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Atf Content"
                name="atfContent"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Atf Content" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Attribution Collection"
                name="attributionCollection"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="attributionCollection" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Attribution Color"
                name="attributionColor"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="attributionColor" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="BTF Reference Links"
                name="btfReferenceLinks"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="BTF Reference Links" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Cross Sells"
                name="crossSells"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Cross Sells" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="design"
                name="design"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="design" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Fabric Description"
                name="fabricDescription"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Fabric Description" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Feature 1"
                name="feature1"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Feature 1" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Feature 2"
                name="feature2"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Feature 2" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Feature 3"
                name="feature3"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Feature 3" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Fit"
                name="fit"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Fit" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Life Occasion"
                name="lifeOccasion"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Life Occasion" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Model Info"
                name="modelInfo"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Model Info" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Pattern"
                name="pattern"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Pattern" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Price"
                name="price"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Price" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Season"
                name="season"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Season" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="seo Description"
                name="seoDescription"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="seo Description" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Silhouette"
                name="silhouette"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Silhouette" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Special Attribute"
                name="specialAttribute"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Special Attribute" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Suggested Style"
                name="suggestedStyle"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Suggested Style" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item
                label="Trend"
                name="trend"
                rules={[
                  {
                    type: 'string',
                  },
                ]}
              >
                <Input placeholder="Trend" />
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Launch" name="launch_masters">
                <Select placeholder="Select" mode="multiple" disabled={true}>
                  {optionList.map((opt) => (
                    <Select.Option value={opt.id}>
                      {opt.launchName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col md={8} lg={8} sm={24} xs={24}>
              <Form.Item label="Collections" name="product_collections">
                <Select placeholder="Select" mode="multiple">
                  {optionListCollection.map((opt) => (
                    <Select.Option value={opt.id} key={opt.id}>
                      {opt.collectionName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Form.Item label="merchendiseId" hidden={true} name="editId">
              <Input type="text" />
            </Form.Item>
          </Row>

          <Button
            className="addProduct_save_btn"
            htmlType="submit"
            type="primary"
            size="large"
          >
            {saving ? (
              <>
                <Spin size="small" /> Saving
              </>
            ) : (
              'Save'
            )}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default CompleteProductView;
