import React from 'react';
import {
  Button,
  Modal,
  Card,
  Col,
  Form,
  Input,
  message,
  Row,
  Spin,
  Table,
  Select,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { API, BEARER } from '../../constant';
import { getToken } from '../../helpers';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { toProperCase } from '../../utils/generic';

const filterGlobalSearch = (Data, searchText, filterScope) => {
  if (!searchText) {
    return Data;
  }

  let filteredDataTemp = [];
  if (filterScope == 'all') {
    filteredDataTemp = Data.filter((value) => {
      console.log(value);
      return (
        value?.supplierName
          ?.toLowerCase()
          .includes(searchText.trim()?.toLowerCase()) ||
        value?.supplierDescription
          ?.toLowerCase()
          .includes(searchText.trim()?.toLowerCase()) ||
        value?.contactPerson
          ?.toLowerCase()
          .includes(searchText.trim()?.toLowerCase()) ||
        value?.contactEmail
          ?.toLowerCase()
          .includes(searchText.trim()?.toLowerCase()) ||
        value?.supplierType
          ?.toLowerCase()
          .includes(searchText.trim()?.toLowerCase()) ||
        value?.gstNo?.toLowerCase().includes(searchText.trim()?.toLowerCase())
      );
    });
  } else if (filterScope == 'supplierName') {
    filteredDataTemp = Data.filter((value) => {
      return value.supplierName
        .toLowerCase()
        .includes(searchText.trim().toLowerCase());
    });
  } else if (filterScope == 'contactPerson') {
    filteredDataTemp = Data.filter((value) => {
      return value.contactPerson
        .toLowerCase()
        .includes(searchText.trim().toLowerCase());
    });
  } else if (filterScope == 'ContactEmail') {
    filteredDataTemp = Data.filter((value) => {
      return value.contactEmail
        .toLowerCase()
        .includes(searchText.trim().toLowerCase());
    });
  } else if (filterScope == 'supplierDescription') {
    filteredDataTemp = Data.filter((value) => {
      return value.supplierDescription
        .toLowerCase()
        .includes(searchText.trim().toLowerCase());
    });
  } else if (filterScope == 'gstNo') {
    filteredDataTemp = Data.filter((value) => {
      return value.gstNo
        .toLowerCase()
        .includes(searchText.trim().toLowerCase());
    });
  }

  console.log(filteredDataTemp, 'filtered data', filterScope, searchText);
  if (filteredDataTemp.length > 0) {
    return filteredDataTemp;
  } else {
    filteredDataTemp.push({
      key: 0,
      id: 0,
      supplierName: 'Not Found',
      supplierDescription: 'Not Found',
      address: {
        addressDescription: 'Not Found',
        addressRefId: 0,
        city: { cityRefId: 0, cityName: 'Not Found' },
        state: { stateRefId: 0, stateName: 'Not Found' },
        country: { countryRefId: 0, countryName: 'Not Found' },
      },
      status: false,
      contactPerson: 'Not Found',
      contactPhone: 'Not Found',
      contactEmail: 'Not Found',
      gstNo: 'Not Found',
    });
    return filteredDataTemp;
  }
};

const SupplierMaster = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page'), 10) || 1;

  const [isLoading, setIsLoading] = useState(null);

  const authToken = getToken();
  const [Data, setData] = useState([]);

  const [filterInfo, setFilterInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState('');

  const [searchText, setSearchText] = useState('');
  const [filterScope, setFilterScope] = useState('all');
  const filterScopeOptionsArray = [
    'all',
    'supplierName',
    'contactPerson',
    'gstNo',
    'ContactEmail',
    'supplierDescription',
  ];

  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);

  const toSupplierDetails = (state) => {
    navigate('/supplierDetails', { state });
  };

  let data1 = [];

  const fetchData = async (token) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${API}/suppler-masters?populate[address][populate][0]=state&populate[address][populate][1]=country&populate[address][populate][2]=city&populate[fabrics][populate]=true&sort=createdAt:desc`,
        {
          headers: { Authorization: `${BEARER} ${token}` },
        }
      );
      const responseData = await response.json();
      console.log(responseData, 'response check');
      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        // return;

        data1 = responseData.data.map((entry) => {
          let addressObj = {};
          if (entry.attributes.address) {
            const addressDetails = entry.attributes.address;
            addressObj = {
              addressRefId: addressDetails.data.id,
              addressDescription:
                addressDetails.data.attributes.addressDescription,
              street: addressDetails.data.attributes.street,
              pincode: addressDetails.data.attributes.pincode,
              state: {
                stateRefId: addressDetails.data.attributes.state.data.id,
                stateName:
                  addressDetails.data.attributes.state.data.attributes
                    .stateName,
              },
              country: {
                countryRefId: addressDetails.data.attributes.country.data.id,
                countryName:
                  addressDetails.data.attributes.country.data.attributes
                    .countryName,
              },
              city: {
                cityRefId: addressDetails.data.attributes.city.data.id,
                cityName:
                  addressDetails.data.attributes.city.data.attributes.cityName,
              },
            };
          }
          let fabricListObject = [];
          if (entry.attributes.fabrics.data.length > 0) {
            fabricListObject = entry.attributes.fabrics?.data.map((fabric) => {
              let fabricRefId = fabric.id;
              let fabricName = fabric.attributes.fabricName;
              return { fabricRefId, fabricName };
            });
          }

          return {
            key: entry.id,
            id: entry.id,
            supplierName: entry.attributes.supplierName,
            supplierDescription: entry.attributes.supplierDescription,
            supplierType: entry.attributes.supplierType,
            address: addressObj,
            status: entry.attributes.status,
            contactPerson: entry.attributes.contactPerson,
            contactPhone: entry.attributes.contactPhone,
            contactEmail: entry.attributes.contactEmail,
            gstNo: entry.attributes.gstNo,
            fabrics: fabricListObject,
          };
        });

        console.log('original Data:', responseData);
        console.log('current Data after modification:', data1);

        setData(filterGlobalSearch(data1, searchText, filterScope));
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authToken) {
      fetchData(authToken);
    }
  }, []);

  useEffect(() => {
    if (searchText == '') {
      fetchData(authToken);
    } else {
      fetchData(authToken);
    }
  }, [searchText, filterScope]);

  if (isLoading) {
    return <Spin size="large" />;
  }

  const columns = [
    {
      key: 'supplierName',
      title: 'Supplier Name',
      dataIndex: 'supplierName',
      sorter: (record1, record2) => {
        return record1.supplierName?.localeCompare(record2.supplierName);
      },
      sortOrder: sortedInfo.columnKey === 'supplierName' && sortedInfo.order,
    },
    {
      key: 'supplierDescription',
      title: 'Supplier Code',
      dataIndex: 'supplierDescription',
    },
    {
      key: 'supplierType',
      title: 'Supplier Type',

      render: (_, record) => {
        return toProperCase(record?.supplierType ?? '');
      },
    },
    {
      key: 'address',
      title: 'Address',
      dataIndex: 'address',
      className: 'address-detail',
      render: (_, record) => {
        // console.log(record);
        //?. is used for not found littral added in case of record not found by filters - now we are keeping the object complete in case of record not found so we dont need ?. operator however for saftly purposes we still kept it.
        return `${record.address?.addressDescription}\n${record.address?.state?.stateName}, ${record.address?.country?.countryName}`;
      },
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      render: (_, record) => {
        return record.status ? 'Active' : 'Not Active';
      },
    },
    {
      key: 'contactPerson',
      title: 'Contact Person',
      dataIndex: 'contactPerson',
    },
    {
      key: 'contactEmail',
      title: 'Contact Email',
      dataIndex: 'contactEmail',
    },
    {
      key: 'gstNo',
      title: 'GST No',
      dataIndex: 'gstNo',
    },
    {
      key: 'action',
      title: 'Actions',

      render: (_, record) => {
        return (
          <div>
            <div className="ActionColumn">
              <EditOutlined
                style={{ color: 'black' }}
                onClick={() => Edit(record)}
              />
              <Button
                icon={
                  <DeleteOutlined style={{ color: 'red', marginRight: 8 }} />
                }
                onClick={() => Delete(record)}
              />
            </div>
          </div>
        );
      },
    },
  ];

  const deleteRecord = async (recordID) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API}/suppler-masters/${recordID}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',

          Authorization: `Bearer ${getToken()}`,
        },
      });
      const responseData = await response.json();
      console.log(responseData);

      if (responseData.error) {
        message.error('Something went wrong, please try again');
      } else {
        fetchData(getToken());
      }
    } catch (error) {
      console.error(error);
      message.error('Error while fetching Data!');
    } finally {
      setIsLoading(false);
    }
  };

  const Delete = (record) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this',
      onOk: () => {
        deleteRecord(record.id);
      },
    });
  };

  const Edit = (record) => {
    toSupplierDetails(record);
  };

  const handleTableChange = (pagination, filters, sorter) => {
    console.log('in handle table change', pagination, filters, sorter);
    const { order, columnKey } = sorter;
    setFilterInfo(filters);
    setSortedInfo({ columnKey, order });
    const { current } = pagination;
    setSearchParams({ page: current });
  };

  const resetTable = () => {
    setSortedInfo({});
    setFilterInfo({});
    setSearchText('');
    setFilterScope('all');
    setAlreadySelectedRows([]);
    fetchData(authToken);
  };

  const handleChangeSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleFilterScopeChange = (value) => {
    console.log('setFilterScope', value);
    setFilterScope(value);
  }; // change in filter scope selected by user

  const getFilterOptionArray = (options) => {
    return options.map((option) => {
      return { value: option, label: option };
    });
  }; //

  const filterOptionArray = [...getFilterOptionArray(filterScopeOptionsArray)];
  return (
    <div>
      <h2>Supplier Master</h2>
      <Button
        onClick={() => {
          toSupplierDetails();
        }}
      >
        Add Supplier Details
      </Button>

      {/* Table Section */}
      <div className="table">
        {/* Filter search Section */}
        <div className="filterContainer">
          <div className="tableSearchSection">
            <Select
              defaultValue="all"
              className="filterScopeSelect"
              onChange={handleFilterScopeChange}
              options={filterOptionArray}
            />

            <Input
              placeholder="Enter Search Text"
              onChange={handleChangeSearch}
              type="text"
              allowClear
              value={searchText}
            ></Input>
            <Button onClick={resetTable}>Reset</Button>
            <Button style={{ backgroundColor: 'greenyellow', color: 'white' }}>
              <CSVLink
                data={Data}
                // data={getFormattedData()}
                filename={
                  'Supplier-Master-Records-list-' +
                  moment(new Date()).format('DD-MM-YYYY')
                }
              >
                Download
              </CSVLink>
            </Button>
          </div>
        </div>
        <Table
          dataSource={Data}
          columns={columns}
          onChange={handleTableChange}
          pagination={{
            current: currentPage,
            pageSize: 10,
            total: Data.length,
            position: ['bottomCenter'],
          }}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: alreadySelectedRows,
            onChange: (keys) => {
              setAlreadySelectedRows(keys);
            },
            onSelect: (record) => {
              console.log(record, 'selected row ');
            },
            selections: [Table.SELECTION_NONE, Table.SELECTION_ALL],
          }}
        />
      </div>
    </div>
  );
};

export default SupplierMaster;
